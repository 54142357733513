var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "pt-0", attrs: { fluid: "" } }, [
    _c("span", { staticClass: "pl-5 pl-md-12 section-title" }, [
      _vm._v(_vm._s(_vm.$t("views.select_company.title")))
    ]),
    _c(
      "div",
      { staticClass: "mx-1 mx-md-8 pt-4" },
      _vm._l(_vm.filteredCompanies, function(item, index) {
        return _c("ItemCard", {
          key: index,
          ref: "company_" + index,
          refInFor: true,
          staticClass: "my-2",
          attrs: {
            title: item.name,
            subtitle: item.parent_id
              ? _vm.$t("views.select_company.subsidiary")
              : _vm.$t("views.select_company.matrix")
          },
          on: {
            click: function($event) {
              return _vm.selectCompany(item)
            }
          },
          scopedSlots: _vm._u(
            [
              {
                key: "aside",
                fn: function() {
                  return [
                    _vm.parentCompany.json.favicon_url
                      ? _c("v-img", {
                          staticClass: "my-auto mr-5",
                          attrs: {
                            src: _vm.parentCompany.json.favicon_url,
                            contain: "",
                            "max-height": "40px",
                            "max-width": "40px"
                          }
                        })
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
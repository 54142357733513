<template>
    <v-card class="elevation-0" @click="$emit('click')">
        <div class="d-flex justify-space-between">
            <div>
                <v-card-title class="pt-3 pb-2 card-title">{{ title }}</v-card-title>
                <v-card-subtitle class="pb-3 card-subtitle secondary_font--text">{{ subtitle }}</v-card-subtitle>
            </div>

            <slot name="aside">
                <div v-if="aside" class="mt-5 mr-4">
                    <span class="card-aside secondary_font--text">{{ aside }}</span>
                </div>
            </slot>
        </div>

    </v-card>
</template>

<script>
export default {
    name: 'ItemCard',
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: true,
        },
        aside: {
            type: String,
        },
    },
}
</script>

<style scoped lang="scss">
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 8px !important;
}

.card-title {
    font-size: 16px;
    font-weight: bold;
}

.card-subtitle, .card-aside {
    font-size: 12px;
}
</style>
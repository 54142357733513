var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "elevation-0",
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-space-between" },
        [
          _c(
            "div",
            [
              _c("v-card-title", { staticClass: "pt-3 pb-2 card-title" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _c(
                "v-card-subtitle",
                { staticClass: "pb-3 card-subtitle secondary_font--text" },
                [_vm._v(_vm._s(_vm.subtitle))]
              )
            ],
            1
          ),
          _vm._t("aside", [
            _vm.aside
              ? _c("div", { staticClass: "mt-5 mr-4" }, [
                  _c(
                    "span",
                    { staticClass: "card-aside secondary_font--text" },
                    [_vm._v(_vm._s(_vm.aside))]
                  )
                ])
              : _vm._e()
          ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
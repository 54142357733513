<template>
    <v-container fluid class="pt-0">
        <span class="pl-5 pl-md-12 section-title">{{ $t('views.select_company.title') }}</span>

        <div class="mx-1 mx-md-8 pt-4">
            <ItemCard
                v-for="(item, index) in filteredCompanies"
                :key="index"
                :ref="`company_${index}`"
                :title="item.name"
                :subtitle="item.parent_id ? $t('views.select_company.subsidiary') : $t('views.select_company.matrix')"
                @click="selectCompany(item)"
                class="my-2"
            >
                <template #aside>
                    <v-img v-if="parentCompany.json.favicon_url" :src="parentCompany.json.favicon_url" contain max-height="40px" max-width="40px" class="my-auto mr-5"></v-img>
                </template>
            </ItemCard>
        </div>
    </v-container>
</template>

<script>
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import ItemCard             from '@/components/cards/ItemCard'
import Subsidiary           from '@/models/Subsidiary'
import Company              from '@/models/Company'
import { mapGetters }       from 'vuex'

export default {
    name: 'SelectCompany',
    mixins: [ HasErrorHandlerMixin ],
    components: { ItemCard },
    async created() {
        await this.$store.dispatch('company/setSelectedCompany', {})
        await this.loadSubsidiaries()
        await this.checkSingleCompany()
    },
    props: {
        to: {
            type: Object,
            default: () => ({ name: 'home' }), // por padrão, vai para a home
        },
    },
    data: vm => ({
        loadedSubsidiaries: [],
        loading           : false,
    }),
    methods: {
        async loadSubsidiaries() {
            this.loading = true
            // [TODO] Paginação Talvez?
            let response = await Company.listSubsidiaries({ per_page: this.$constants.getConstant('PAGINATION_MAX_PER_PAGE') })
                .catch(this.errorHandler)
            this.loading = false

            if (!response)
                return

            this.loadedSubsidiaries = response.data.map(d => new Subsidiary(d))
        },
        async selectCompany(company) {
            await this.$store.dispatch('company/setSelectedCompany', company)

            this.goToNextRoute()
            this.$bus.$emit('message', this.$t('views.select_company.company_selected', { name: company.name }), 'success')
        },
        async checkSingleCompany() {
            if (this.filteredCompanies.length == 1)
                await this.selectCompany(this.filteredCompanies[0])
        },
        goToNextRoute() {
            this.$router.push(this.to)
        },
    },
    computed: {
        ...mapGetters({
            jwtContent   : 'auth/jwtContent',
            parentCompany: 'company/state',
        }),

        allCompanies() {
            return [
                this.$lodash.pick(this.parentCompany, ['id', 'name', 'document']),
                ...this.loadedSubsidiaries,
            ]
        },

        filteredCompanies() {
            // Verifica se este usuário tem acesso ao escopo da matriz
            if (this.jwtContent && this.jwtContent.roles.some(r => r.company_id == this.parentCompany.id))
                return this.allCompanies

            // Senão, só retorna as company que ele tem acesso
            let subsidiariesIds = this.jwtContent ? this.jwtContent.roles.map(r => r.company_id) : []
            return this.allCompanies.filter(c => subsidiariesIds.includes(c.id))
        },
    },
}
</script>